import {log, localScrollToDomElement} from "@/shared/utils";
import lang from "@/shared/lang";

// define a mixin object
export const formWrapperMixin = {
    data() {
        return {
            watchFormRef: ""
        }
    },
    computed: {
        doPreventNavigation() {
            return this.watchFormRef !== "";
        },
        shouldPreventNavigation() {
            return !this.checkFormNavigationOK();
        },
        myLog(){
            return log;
        },
        text(){
            return lang;
        }
    },
    methods: {
        getFormDataValueByID (formData, id) {
            return formData.find(element => element.id === id).value;
        },
        getFormDataValueByName (formData, name) {
            // log(["Getting Form Value by Name",name,formData.find(element => element.name === name).value]);
            return formData.find(element => element.name === name).value;
        },
        setFormDataValueByName (formData, name, newValue) {
            return formData.find(element => element.name === name).value = newValue;
        },
        setFormDataOptionsByName (formData, name, options) {
            return formData.find(element => element.name === name).options = options;
        },

        /**
         * Prevent navigation if user has changed a form
         */
        setWatchFormRef(ref) {
            log(["WATCH FORM SET", ref]);
            this.watchFormRef = ref;
        },

        /**
         * API to Form structure methods
         */
        buildFormStructureRow (index, rowData, context = "template") {
            log(["Building form structure row", index, rowData, context]);

            let newRow = {
                id: index,
                type: this.convertFormElementTypeFromAPI(rowData.editorType, context),
                name: rowData.alias,
                label: rowData.label ? rowData.label : rowData.name,
                placeholder: "",
                tooltip: rowData.tooltip,
                locked: rowData.locked,
                value : this.getValue(rowData, context),
                isTemplateContentEdit : context === "template",
                isAdvertContentEdit : context === "advert",
                readonly : context.includes("view") || rowData.locked,

                // OPTIONAL EXTRAS
                // disabled : true,
                // readonly : true,
                // errors : []
            };

            newRow.validate = this.buildValidateObject(rowData, context, newRow);

            /**
             * 20210311
             * Check for indent
             */
            if(rowData.hasOwnProperty("configuration") && rowData.configuration.hasOwnProperty("settings")){
                newRow.indent = rowData.configuration.settings.hasOwnProperty("indent") ? rowData.configuration.settings.indent : false;
            }

            /**
             * Pickers require additional information
             */
            if(this.convertFormElementTypeFromAPI(rowData.editorType, context).includes("picker")){

                if(rowData.hasOwnProperty("configuration") && rowData.configuration.hasOwnProperty("data")){
                    newRow.ignoreOptions = rowData.configuration.data.hasOwnProperty("ignoreOptions") ? rowData.configuration.data.ignoreOptions : false;

                    if(rowData.configuration.data.hasOwnProperty("options")){
                        newRow.options = rowData.configuration.data.options;

                        let selectedOptions = [];

                        rowData.configuration.data.options.forEach((option) => {
                            log(["Looping through picker options", option, option.selected, this.convertFormElementTypeFromAPI(rowData.editorType, context)]);
                            if(this.convertFormElementTypeFromAPI(rowData.editorType, context).includes("mediapicker")){
                                log("Is media");
                                selectedOptions.push(option.id);
                            }
                            else if(option.selected)
                            {
                                log("Is text and selected");
                                selectedOptions.push(option.id);
                            }
                        });

                        log(["selected options", selectedOptions]);
                        newRow.selectedOptions = selectedOptions;
                    }
                    else
                    {
                        newRow.options = [];
                        newRow.selectedOptions = [];
                    }
                }
                else
                {
                    newRow.ignoreOptions = false;
                    newRow.options = [];
                    newRow.selectedOptions = [];
                }
            }

            /**
             * Need to make sure all advert fields are required
             */
            if(context.toLowerCase().includes("advert")){
                newRow.required = rowData.required;
            }

            return newRow;
        },
        buildDisabledFormStructureRow(index, rowData){
            let newRow = {
                id: index,
                type: "disabled",
                name: rowData.alias,
                label: rowData.label ? rowData.label : rowData.name,
                placeholder: "",
                locked: false,
                value : "",
            };

            /**
             * 20210311
             * Check for indent
             */
            if(rowData.hasOwnProperty("configuration") && rowData.configuration.hasOwnProperty("settings")){
                newRow.indent = rowData.configuration.settings.hasOwnProperty("indent") ? rowData.configuration.settings.indent : false;
            }

            return newRow;
        },
        convertFormElementTypeFromAPI(string, context){
            if( ["Textarea"].includes(string) ) {
                return "textarea";
            }
            else if(["TextareaPicker"].includes(string)) {
                if(context === "template"){
                    return "textareapicker_edit";
                }
                else if(context === "templateview") {
                    return "textareapicker_view";
                }
                else if(context === "advertview") {
                    return "textareapicker_value";
                }
                else {
                    return "textareapicker_select";
                }
            }
            else if(["MediaPicker"].includes(string)) {
                if(context === "template"){
                    return "mediapicker_edit";
                }
                else if(context === "templateview") {
                    return "mediapicker_view";
                }
                else if(context === "advertview") {
                    return "mediapicker_value";
                }
                else
                {
                    return "mediapicker_select";
                }
            }
            else if(["TextPicker"].includes(string)) {
                if(context === "template"){
                    return "textpicker_edit";
                }
                else if(context === "templateview") {
                    return "textpicker_view";
                }
                else if(context === "advertview") {
                    return "textpicker_value";
                }
                else
                {
                    return "textpicker_select";
                }
            }
            else
            {
                return "text";
            }
        },

        getValue(rowData, context){

            if(context.includes("advert") && rowData.hasOwnProperty('value') && rowData.value.hasOwnProperty('value') && rowData.value.value){
                return rowData.value.value;
            }
            else
            {
                // Doesn't need to be a method now, but when more
                // complex editor types come along may need some logic here
                return rowData.defaultValue.hasOwnProperty("value") ? rowData.defaultValue.value : "";
            }
        },

        buildValidateObject(rowData, context, scope){
            let validateObject = {};

            /**
             * Validate type
             */
            if(["Text", "Textarea"].includes(rowData.editorType)){
                validateObject.type = "string";
            }
            else if(["TextPicker", "TextareaPicker"].includes(rowData.editorType))
            {
                validateObject.type = function(){
                    log(["Function returning type based on ignoreOptions", scope.ignoreOptions]);
                    if(scope.ignoreOptions || context === "advert"){
                        return "string";
                    }
                    else
                    {
                        return "picker";
                    }
                };
            }
            else if(["MediaPicker"].includes(rowData.editorType))
            {
                validateObject.type = "picker";
            }

            /**
             * Required
             */
            if(context === "template"){
                // generally a value isn't required in a template unless
                // the user locks the field. In this case text versions require a value
                // and pickers require at least one option
                validateObject.required = function(){
                    log(["Validate function in template form object: Is this locked?", this.type, scope.locked]);
                    return scope.locked;
                }
            }
            else if(context === "advert")
            {
                validateObject.required = function(){
                    log(["Validate function in advert form object: Is this required?", this.type, scope.required]);
                    return scope.required;
                }

                validateObject.liveValidate = true;
                validateObject.liveStatus = false;
                validateObject.hasContent = false;
            }

            /**
             * Max length
             */

            if(["MediaPicker"].includes(rowData.editorType) && context === "advert"){
                // We only want one item selected in an advert!
                validateObject.max = 1;
                validateObject.min = 1;
            }
            else
            {
                let maxLength = this.getConfigurationObjectValue(rowData, "settings","maxLength")
                if(maxLength !== undefined){
                    validateObject.max = maxLength;
                }
            }

            return validateObject;
        },
        getConfigurationObject(rowData, objectName){
            if(rowData.configuration.hasOwnProperty(objectName)){
                return rowData.configuration[objectName];
            }
            else
            {
                return undefined;
            }
        },
        getConfigurationObjectValue(rowData, objectName, propertyName){
            let configurationObject = this.getConfigurationObject(rowData, objectName);
            if(configurationObject !== undefined){
                if(configurationObject.hasOwnProperty(propertyName)){
                    return configurationObject[propertyName];
                }
                else
                {
                    return undefined;
                }
            }
            else
            {
                return undefined;
            }
        },
        scrollToFirstError(){
            let errorElements = document.getElementsByClassName('form-input error');
            if(errorElements.length > 0){
                localScrollToDomElement(errorElements[0]);
            }
        },
        checkFormNavigationOK(){
            log(["Can I navigate away?", (!this.doPreventNavigation || (this.doPreventNavigation && this.watchFormRef !== "" && !this.$refs[this.watchFormRef].hasChanged()))]);
            if (!this.doPreventNavigation || (this.doPreventNavigation && this.watchFormRef !== "" && !this.$refs[this.watchFormRef].hasChanged())) {
                return true;
            }
            else
            {
                return false;
            }
        },

    },
    beforeRouteLeave (to, from, next) {
        log(["BEFORE ROUTE LEAVE", next, this.doPreventNavigation]);

        if(this.checkFormNavigationOK()){
            next();
        }
        else
        {
            const answer = window.confirm(lang.unsaved_navigation_confirm);

            if(answer){
                next();
            }
            else
            {
                next(false);
            }
        }
    },
}
